@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap"');
@import "../Logic/GlobalConsts.scss";

/* Primary Styles */
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-size: 1em;
  color: #333;
}

h1 {
  font-size: 1.4em;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.s-layout__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.operation-filter-input {
  max-width: 1000px !important;
}


/* SideNav */

.s-sidebar__trigger {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: var(--header_height);
  height: var(--header_height);
  background: rgba(0, 0, 0, 0.1);
}

.s-sidebar__nav {
  position: fixed;
  z-index: 1;
  left: -15em;
  top: 0px;
  transition: all .3s ease-in;
  width: 15em;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  display: flex;
  flex-direction: column;
}

.s-sidebar__search {
  margin: 15px;
  visibility: hidden;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.header {
  height: 95px;
}

.s-sidebar__search input {
  border: none;
  margin-left: 10px;
  width: 130px;
}

.s-sidebar__navItems {
  flex: 1 1 auto;
  overflow-y: auto;
  text-transform: capitalize;
}
.innerDiv {
  width:10em;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}


.s-sidebar__navItems ul {
  left: 0;
  margin: 0;
  padding: 0;
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav-link,.s-sidebar__inner_nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top:5px;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  text-align: left;
  cursor: pointer;
}
.s-sidebar__inner_nav-link {
  position: relative;
  left: 15px;
  word-break: break-word;
  font-weight: 300;
  font-size: 12px;
}

.s-sidebar__nav-link em {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.s-sidebar__inner_nav-link em {
  position: relative;
  text-transform: none;
}
.s-sidebar__nav-text_selected, .s-sidebar__inner_nav-text_selected {
  color: rgba(85, 108, 214, 0.9)!important;
}

.s-sidebar__nav-link:hover , .s-sidebar__inner_nav-link:hover{
  color: black;
}

.s-sidebar__nav-link :before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.barsIcon {
  display: inline-block;
  margin: calc((var(--header_height) / 2) - 8px) 0 0 calc((var(--header_height) / 2) - 7px);
  color: black;
}

.searchIcon {
  display: inline-block;
  margin-right: 0.2em;
  color: rgba(0, 0, 0, 0.6);;
}

@media (min-width: 50em) {

  .s-sidebar__trigger {
    width: 8em;
    display: none
  }

  .s-sidebar__nav {
    width: 15em;
    left: 0;
  }

  .s-sidebar__search {
    visibility: visible;
  }
  .header {
    height: 115px;
    flex: none;
  }
}


@media (min-width: 50em) {
  .s-layout__content {
    margin-left: 15em;
  }

  .operation-filter-input {
    position: fixed;
    top: 55px;
    left: 15px;
    width: 13em !important;
    height: 30px;
    z-index: 20;
  }
}


