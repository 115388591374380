.DropdownMenu{
  margin-left:3px;
  width:20%;
  float:left;
}
.DropdownMenu p {
  margin:0;
  font-weight: bold;
  font-size:12px;
}
.AuthDropdowns {
  margin-top:-20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 90em) {
  .DropdownMenu{
    width:25%
  }
}
@media (max-width: 50em) {
  .DropdownMenu{
    width:40%
  }
  .AuthDropdowns {
    justify-content: center;
  }
}