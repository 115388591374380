@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#SwaggerUIContainer{
  width:100%;
  align-self: flex-start ;
}


:root {
  --header_height:58px
; }

/* Primary Styles */
*, *::before, *::after {
  box-sizing: border-box; }

body {
  font-family: sans-serif;
  font-size: 1em;
  color: #333; }

h1 {
  font-size: 1.4em; }

em {
  font-style: normal; }

a {
  text-decoration: none;
  color: inherit; }

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  min-height: 100vh; }

.s-layout__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1; }

.operation-filter-input {
  max-width: 1000px !important; }

/* SideNav */
.s-sidebar__trigger {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 58px;
  width: var(--header_height);
  height: 58px;
  height: var(--header_height);
  background: rgba(0, 0, 0, 0.1); }

.s-sidebar__nav {
  position: fixed;
  z-index: 1;
  left: -15em;
  top: 0px;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  width: 15em;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  display: flex;
  flex-direction: column; }

.s-sidebar__search {
  margin: 15px;
  visibility: hidden;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.header {
  height: 95px; }

.s-sidebar__search input {
  border: none;
  margin-left: 10px;
  width: 130px; }

.s-sidebar__navItems {
  flex: 1 1 auto;
  overflow-y: auto;
  text-transform: capitalize; }

.innerDiv {
  width: 10em; }

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0; }

.s-sidebar__navItems ul {
  left: 0;
  margin: 0;
  padding: 0; }

.s-sidebar__nav ul li {
  width: 100%; }

.s-sidebar__nav-link, .s-sidebar__inner_nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  text-align: left;
  cursor: pointer; }

.s-sidebar__inner_nav-link {
  position: relative;
  left: 15px;
  word-break: break-word;
  font-weight: 300;
  font-size: 12px; }

.s-sidebar__nav-link em {
  position: absolute;
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.s-sidebar__inner_nav-link em {
  position: relative;
  text-transform: none; }

.s-sidebar__nav-text_selected, .s-sidebar__inner_nav-text_selected {
  color: rgba(85, 108, 214, 0.9) !important; }

.s-sidebar__nav-link:hover, .s-sidebar__inner_nav-link:hover {
  color: black; }

.s-sidebar__nav-link :before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.barsIcon {
  display: inline-block;
  margin: calc((58px / 2) - 8px) 0 0 calc((58px / 2) - 7px);
  margin: calc((var(--header_height) / 2) - 8px) 0 0 calc((var(--header_height) / 2) - 7px);
  color: black; }

.searchIcon {
  display: inline-block;
  margin-right: 0.2em;
  color: rgba(0, 0, 0, 0.6); }

@media (min-width: 50em) {
  .s-sidebar__trigger {
    width: 8em;
    display: none; }
  .s-sidebar__nav {
    width: 15em;
    left: 0; }
  .s-sidebar__search {
    visibility: visible; }
  .header {
    height: 115px;
    flex: none; } }

@media (min-width: 50em) {
  .s-layout__content {
    margin-left: 15em; }
  .operation-filter-input {
    position: fixed;
    top: 55px;
    left: 15px;
    width: 13em !important;
    height: 30px;
    z-index: 20; } }

.DropdownMenu {
  margin-left: 3px;
  width: 20%;
  float: left; }

.DropdownMenu p {
  margin: 0;
  font-weight: bold;
  font-size: 12px; }

.AuthDropdowns {
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: flex-end; }

@media (max-width: 90em) {
  .DropdownMenu {
    width: 25%; } }

@media (max-width: 50em) {
  .DropdownMenu {
    width: 40%; }
  .AuthDropdowns {
    justify-content: center; } }

.markdown {
  padding-left: 20px;
  margin-bottom: 30px; }

h1 {
  font-size: 24px !important;
  color: #2a2f45;
  font-weight: 300;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif; }

.markdown h2 {
  font-size: 16px !important;
  color: #4f566b;
  font-weight: 600;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif; }

.markdown h3 {
  font-size: 14px !important;
  color: #4f566b !important;
  font-weight: 600;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif !important; }

.markdown h4 {
  font-size: 12px !important;
  color: #4f566b !important;
  font-weight: bold;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif; }

.markdown code {
  color: rgba(85, 108, 214, 0.9) !important; }

.opblock-tag .nostyle span {
  margin-left: 7px;
  font-size: 23px;
  color: #4f566b;
  text-transform: capitalize; }

.opblock-tag {
  cursor: default !important;
  background-color: rgba(227, 231, 255, 0.7);
  border: none !important;
  position: relative; }

.opblock-tag:hover {
  background-color: rgba(227, 231, 255, 0.7) !important; }

.opblock-tag-section {
  margin-bottom: 60px;
  position: relative; }

.hover {
  background-color: rgba(66, 133, 244, 0.3); }

.opblock-tag-section::after {
  content: " ";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: -30px;
  background-color: #dadada; }

.opblock-tag-section hr {
  width: 100%;
  margin: 30px 0 30px 0;
  border: 0;
  border-top: 1px solid rgba(79, 86, 107, 0.2); }

.opblock-tag .expand-operation {
  position: absolute !important;
  right: 30px !important; }

.opblock-tag-section.is-open > div {
  margin-top: 20px; }

#swagger-ui-mountpoint {
  flex-grow: 1; }

.swagger-ui .auth-wrapper .authorize {
  margin-right: 0; }

.swagger-ui .scheme-container {
  margin: 0px;
  box-shadow: 0px 0px 0px #fff; }

.CollapseOperations {
  cursor: pointer;
  background-color: rgba(227, 231, 255, 0.4);
  margin: 0 0 5px;
  font-family: 'Roboto', sans-serif;
  color: rgba(79, 86, 107, 0.9);
  padding: 10px 20px 10px 20px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-weight: bold;
  font-size: 15px; }

.CollapseOperations:hover {
  background-color: rgba(85, 108, 214, 0.2); }

.arrow {
  color: rgba(79, 86, 107, 0.9);
  width: 15px !important;
  height: 15px !important; }

@media (max-width: 20em) {
  .expand-operation {
    position: static !important; } }

.header-wrapper {
  background-color: #4285f4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; }

.header-content-wrapper {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; }

.size-normal {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto; }

.header-title {
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center; }

.header-api-logo svg {
  height: 50px;
  margin-top: 4px; }

.header-text {
  margin-left: 10px; }

.user {
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  font-weight: bold; }

.header-title-fake {
  height: 58px; }

@media (max-width: 50em) {
  .mainPage .header-title {
    margin-left: 45px; }
  .user {
    font-size: 11px; }
  .header-title {
    font-size: 11px; } }

@media (max-width: 22em) {
  .header-text {
    margin-bottom: 4px; }
  .user {
    font-size: 10px; }
  .header-title {
    font-size: 10px; }
  .mainPage .header-title {
    margin-left: 0; }
  .mainPage .header-api-logo svg {
    height: 50px;
    width: 40px;
    margin-top: 4px;
    visibility: hidden; } }

.api-list-wrapper {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box; }
  .api-list-wrapper ul {
    list-style-type: none;
    padding-left: 0; }
  .api-list-wrapper a {
    font-family: 'Roboto', sans-serif;
    color: #666666; }
  .api-list-wrapper a:hover {
    cursor: pointer;
    color: black; }

.project-list-link .project-list-link-items {
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  padding-left: 2px; }
  .project-list-link .project-list-link-items:hover {
    background-color: #f0f0f0; }
  .project-list-link .project-list-link-items .project-list-link-item {
    padding: 7px 0;
    width: 33%; }
  .project-list-link .project-list-link-items .text-center {
    text-align: center; }
  .project-list-link .project-list-link-items .text-right {
    text-align: right; }
  .project-list-link .project-list-link-items .text-bold {
    font-weight: bold; }

.error-wrapper {
  background-color: #9e0000;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  color: white; }

.message-wrapper {
  background-color: #4285f4;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  text-align: center; }

.messageLink {
  color: darkorange !important;
  text-decoration: underline; }

.messageImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%; }

@media (max-width: 50em) {
  img {
    margin: 0px;
    width: 100%; } }

.centeredContent {
  padding: 20px;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 4px 1px #bdb7bd;
  text-align: center; }
  .centeredContent .formTitle {
    margin: 10px;
    text-align: center;
    font-size: 1.8em;
    font-weight: 300;
    line-height: 1.1;
    color: #505050;
    font-family: "wf_segoe-ui_normal", "Segoe UI", "Segoe WP", "Tahoma", "Arial", "sans-serif"; }
  .centeredContent .signUpForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }
  .centeredContent p {
    font-size: 14px;
    color: #505050;
    margin-top: 7px;
    margin-bottom: 0;
    font-family: "wf_segoe-ui_normal", "Segoe UI", "Segoe WP", "Tahoma", "Arial", "sans-serif"; }
  .centeredContent .inputLabel {
    color: #505050;
    font-weight: bold; }
  .centeredContent a {
    color: #00bcf8; }
  .centeredContent a:hover {
    color: #0c72c6;
    text-decoration: underline; }
  .centeredContent input[type=text] {
    height: 33px;
    margin-top: 3px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid #ccc;
    padding-left: 10px; }
  .centeredContent input[type=text]:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .centeredContent input[type=submit] {
    margin-top: 8px;
    background-color: #00bcf8;
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    border: 1px solid transparent;
    margin-bottom: 10px; }
  .centeredContent input[type=submit]:hover {
    background-color: #0095c5;
    border-color: #007aa1; }
  .centeredContent .invalidMessage {
    background-color: #f2dede;
    color: #a94442;
    padding: 15px;
    margin-bottom: 20px;
    margin-top: 10px; }
  .centeredContent ul {
    margin: 0; }
  .centeredContent li {
    font-size: 14px; }
  @media (max-width: 1000px) {
    .centeredContent {
      margin-left: 20%;
      margin-right: 20%; } }
  @media (max-width: 600px) {
    .centeredContent {
      margin-left: 10%;
      margin-right: 10%; } }
  @media (max-width: 500px) {
    .centeredContent {
      padding: 5px; }
      .centeredContent .signUpForm {
        width: 100%; } }

