
.header-wrapper {
  background-color: #4285f4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.header-content-wrapper {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between
}

.size-normal {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
}

.header-title {
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.header-api-logo svg {
  height: 50px;
  margin-top: 4px;
}

.header-text {
  margin-left: 10px;
}

.user {
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  font-weight: bold;
}

.header-title-fake {
  height: 58px;
}

@media (max-width: 50em) {
  .mainPage {
    .header-title {
      margin-left: 45px;
    }
  }
  .user {
    font-size: 11px;
  }
  .header-title {
    font-size: 11px;
  }
}

@media (max-width: 22em) {

  .header-text{
    margin-bottom: 4px;
  }
  .user {
    font-size: 10px;
  }
  .header-title {
    font-size: 10px;
  }
  .mainPage {
    .header-title {
    margin-left: 0;
  }
    .header-api-logo svg {
      height: 50px;
      width: 40px;
      margin-top: 4px;
      visibility: hidden;
    }
  }

}