@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap"');
.api-list-wrapper {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  ul {
    list-style-type: none;
    padding-left: 0;
  }
  a{
    font-family: 'Roboto', sans-serif;
    color:rgb(102, 102, 102);
  }
  a:hover {
    cursor: pointer;
    color: black;
  }
}

.project-list-link {


  .project-list-link-items {
    border-bottom: 1px solid rgb(223, 223, 223);
    display: flex;
    justify-content: space-between;
    padding-left: 2px;

    &:hover {
      background-color: rgb(240, 240, 240);
    }

    .project-list-link-item {
      padding: 7px 0;
      width: 33%;

    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .text-bold {
      font-weight: bold;
    }
  }
}
