.error-wrapper {
    background-color: #9e0000;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    color: white
}

.message-wrapper {
    background-color: #4285f4;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    text-align: center;
}
.messageLink{
    color:darkorange !important;
    text-decoration: underline
}
.messageImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
@media (max-width: 50em) {
    img{
        margin:0px;
        width:100%;
    }
}