.markdown {
  padding-left: 20px;
  margin-bottom:30px;
}
h1{
  font-size:24px !important;
  color:rgb(42, 47, 69);
  font-weight: 300;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif;
}
.markdown h2{
  font-size:16px !important;
  color:rgb(79, 86, 107);
  font-weight: 600;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif;
}
.markdown h3{
  font-size:14px !important;
  color:rgb(79, 86, 107) !important;
  font-weight: 600;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif !important;
}
.markdown h4{
  font-size:12px !important;
  color:rgb(79, 86, 107) !important;
  font-weight: bold;
  letter-spacing: 0.63px;
  font-family: 'Roboto', sans-serif;
}
.markdown code{
  color: rgba(85, 108, 214, 0.9) !important;
}
.opblock-tag .nostyle span{
  margin-left:7px;
  font-size:23px;
  color:rgb(79, 86, 107);
  text-transform: capitalize;
}

.opblock-tag {
  cursor:default !important;
  background-color: rgba(227,231,255,0.7);
  border:none !important;
  position:relative;
}
.opblock-tag:hover {
  background-color: rgba(227,231,255,0.7) !important;
}
.opblock-tag-section {
  margin-bottom: 60px;
  position: relative;
}
.hover {
  background-color: rgba(66, 133, 244,0.3);
}
.opblock-tag-section::after {
  content: " ";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: -30px;
  background-color: rgb(218, 218, 218);
}

.opblock-tag-section hr{
  width: 100%;
  margin:30px 0 30px 0;
  border: 0; border-top: 1px solid rgba(79, 86, 107,0.2);
}
.opblock-tag .expand-operation {
  position: absolute !important;
  right: 30px !important;

}

.opblock-tag-section.is-open > div {
  margin-top: 20px;

}

#swagger-ui-mountpoint{
  flex-grow: 1;
}

.swagger-ui .auth-wrapper .authorize {
  margin-right: 0;
}

.swagger-ui .scheme-container {
  margin: 0px;
  box-shadow: 0px 0px 0px #fff;
}

.swagger-ui .filter .operation-filter-input {
}
//custom classes added to swagger files
.CollapseOperations{
  cursor: pointer;
  background-color: rgba(227,231,255,0.4);
  margin: 0 0 5px;
  font-family: 'Roboto', sans-serif;
  color:rgba(79, 86, 107,0.9);
  padding: 10px 20px 10px 20px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-weight: bold;
  font-size:15px;
}

.CollapseOperations:hover{
  background-color: rgba(85, 108, 214, 0.2);
}

.arrow{
  color:rgba(79, 86, 107,0.9);
  width:15px !important;
  height:15px !important;
}

//media queries
@media (max-width: 20em)
{
  .expand-operation {
    position: static !important;

  }
}