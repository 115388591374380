@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap"');

.centeredContent {
  padding: 20px;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 4px 1px rgba(189, 183, 189, 1);
  text-align: center;

  .formTitle {
    margin: 10px;
    text-align: center;
    font-size: 1.8em;
    font-weight: 300;
    line-height: 1.1;
    color: rgb(80, 80, 80);
    font-family: "wf_segoe-ui_normal", "Segoe UI", "Segoe WP", "Tahoma", "Arial", "sans-serif";
  }

  .signUpForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  p {
    font-size: 14px;
    color: #505050;
    margin-top: 7px;
    margin-bottom: 0;
    font-family: "wf_segoe-ui_normal", "Segoe UI", "Segoe WP", "Tahoma", "Arial", "sans-serif";
  }

  .inputLabel {
    color: #505050;
    font-weight: bold;
  }

  a {
    color: rgb(0, 188, 248);
  }

  a:hover {
    color: #0c72c6;
    text-decoration: underline;
  }

  input[type=text] {
    height: 33px;
    margin-top: 3px;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid #ccc;
    padding-left: 10px;
  }

  input[type=text]:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  input[type=submit] {
    margin-top: 8px;
    background-color: rgb(0, 188, 248);
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    border: 1px solid transparent;
    margin-bottom: 10px;
  }

  input[type=submit]:hover {
    background-color: #0095c5;
    border-color: #007aa1;
  }

  .invalidMessage {
    background-color: #f2dede;
    color: #a94442;
    padding: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  ul {
    margin: 0;
  }

  li {
    font-size: 14px;
  }

  @media (max-width: 1000px) {
    margin-left: 20%;
    margin-right: 20%;
  }
  @media (max-width: 600px) {
    margin-left: 10%;
    margin-right: 10%;
  }
  @media (max-width: 500px) {
    padding: 5px;
    .signUpForm {
      width: 100%;
    }
  }
}